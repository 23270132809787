<template>
  <div>
    <div class="px-6">
      <header class="py-2.5 text-lg flex text-text-alternate-1 pr-6">
        <button class="focus:outline-none" @click="$router.go(-1)">
          <LeftArrowIcon class="h-6 fill-current" />
        </button>
        <h2 class="mx-auto font-semibold">Promote & Earn Wallet</h2>
      </header>
      <main>
        <div
          class="block rounded bg-bg-alternate-9 p-4 sm:py-6 my-2 text-center"
        >
          <h5 class="font-medium uppercase text-xs text-text-alternate-13">
            wallet balance
          </h5>
          <p class="font-bold text-lg pt-2">
            ₦ {{ walletResponse.data ? walletResponse.data?.balance : 0 }}
          </p>
        </div>

        <hr class="my-4 text-text-alternate-8" />

        <p class="text-xs font-semibold text-center uppercase mb-4">
          withdrawal history
        </p>

        <ul v-if="withdrawalsResponse.data.length">
          <li
            v-for="(withdrawal, index) in withdrawalsResponse.data"
            :key="index"
            class="border-t border-border-primary py-2 flex items-center"
          >
            <span
              class="
                rounded-full
                font-bold
                bg-bg-alternate-3
                text-sm
                h-8
                w-8
                flex flex-shrink-0
                justify-center
                items-center
              "
              :style="{
                backgroundColor: randomColor({
                  luminosity: 'light',
                  seed: `${authUser.firstname[0]}`,
                  format: 'rgba',
                  alpha: 0.3,
                }),
                color: randomColor({
                  luminosity: 'bright',
                  seed: `${authUser.firstname[0]}`,
                }),
              }"
            >
              {{ authUser.firstname[0] }}
            </span>
            <div class="ml-4 w-full">
              <h4 class="flex justify-between text-sm py-1">
                <span class="font-semibold">Withdrawal</span>
                <span class="font-bold text-text-alternate-14">
                  - ₦{{ withdrawal.amount }}
                </span>
              </h4>
              <p
                class="
                  flex
                  justify-between
                  text-2xs text-text-alternate-7
                  font-medium
                  py-1
                "
              >
                <span class="flex">
                  <span class="mr-2">
                    {{ dayjs(withdrawal.createdAt).format('DD MMM YYYY') }}
                  </span>
                  <span>
                    {{ dayjs(withdrawal.createdAt).format('HH:mm:ss') }}
                  </span>
                </span>
                <span
                  class="font-semibold"
                  :class="
                    withdrawal.status == 'PAID'
                      ? 'text-text-alternate-6'
                      : withdrawal.status == 'REJECTED' ||
                        withdrawal.status == 'UNSUCCESFUL'
                      ? 'text-text-danger'
                      : withdrawal.status == 'PENDING'
                      ? 'text-text-alternate-15'
                      : ''
                  "
                >
                  {{ withdrawal.status }}</span
                >
              </p>
            </div>
          </li>
        </ul>

        <div v-else class="flex pt-8 flex-col items-center justify-center">
          <span class="p-6 bg-bg-alternate-3 rounded-full">
            <ResultEmptyIcon class="fill-current h-10" />
          </span>
          <h4 class="font-medium capitalize text-md mt-8">
            No withdrawals yet!
          </h4>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
// import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import randomColor from 'randomcolor';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax';
import { useApi } from '@/cmp-functions/useApi';
import {
  getPromoteAndEarnWallet,
  getPromoteAndEarnWithdrawals,
} from '@/services/api';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(minMax);
const store = useStore();
const route = useRoute();
const authUser = store.state.auth.authData;

const userId = route.params.userId;
const [walletResponse, fetchPromoteAndEarnWallet] = useApi(
  getPromoteAndEarnWallet
);
fetchPromoteAndEarnWallet(userId);

const [withdrawalsResponse, fetchPromoteAndEarnWithdrawals] = useApi(
  getPromoteAndEarnWithdrawals
);

watch(walletResponse, ({ isSuccess }) => {
  if (isSuccess) {
    fetchPromoteAndEarnWithdrawals(walletResponse.data.id);
  }
});
</script>
